<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            maxlength="40"
            v-model="user.name"
            :rules="[rules.required]"
            label="Nome"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            maxlength="20"
            v-model="user.login"
            :rules="[rules.required]"
            label="Login"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!user.id">
        <v-col cols="12" md="3">
          <v-text-field
            maxlength="12"
            v-model="user.password"
            :type="show1 ? 'text' : 'password'"
            :rules="[rules.required, rules.min]"
            label="Senha"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show1 = !show1"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            maxlength="12"
            v-model="user.confirm_pass"
            :type="show2 ? 'text' : 'password'"
            :rules="[
              rules.required,
              rules.min,
              user.password === user.confirm_pass || 'Senhas não conferem',
            ]"
            label="Repita"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show2 = !show2"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <div v-if="$vuetify.breakpoint.mobile">
        <v-btn :disabled="!valid" color="success" class="mr-4" @click="save">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn color="warning" class="mr-4" @click="reset"
          ><v-icon>mdi-broom</v-icon></v-btn
        >
        <v-btn color="error" to="/usuarios"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div v-if="!$vuetify.breakpoint.mobile">
        <v-btn :disabled="!valid" color="success" class="mr-4" @click="save"
          >Salvar</v-btn
        >
        <v-btn color="warning" class="mr-4" @click="reset">Limpar</v-btn>
        <v-btn color="error" to="/usuarios">Cancelar</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
export default {
  name: "UserForm",
  data: () => ({
    show1: false,
    show2: false,
    valid: false,
    user: {},
    rules: {
      required: (value) => !!value || "Obrigatorio.",
      min: (v) => (v && v.length >= 6) || "Minimo 6 caracteres!",
    },
  }),
  methods: {
    async save() {
      await this.$refs.form.validate();
      if (!this.valid) return;

      const method = this.user.id ? "put" : "post";
      const url = this.user.id
        ? `${baseApiUrl}/user/${this.user.id}`
        : `${baseApiUrl}/users`;
      axios[method](url, this.user)
        .then(() => {
          this.$router.push("/usuarios");
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data);
          }
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  //   watch: {
  //     user: function () {
  //       this.user.password === this.user.confirm_pass ?
  //       this.debouncedGetAnswer();
  //     },
  //   },
  mounted() {
    if (this.$route.params.id) {
      const url = `${baseApiUrl}/user/${this.$route.params.id}`;
      axios.get(url).then((res) => {
        this.user = res.data;
      });
    }
  },
};
</script>

<style>
.input-uppercase input {
  text-transform: uppercase;
}
</style>